import React from 'react'

function IconFileOptions(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 56 56">
      <path
        fill="#FFF4F1"
        d="M43.903 14.204l-7.456-7.456a5.275 5.275 0 00-3.728-1.545h-18.91a5.272 5.272 0 00-5.273 5.273v36.912a5.272 5.272 0 005.274 5.273h26.365a5.272 5.272 0 005.273-5.273V17.932c0-1.397-.556-2.74-1.545-3.728z"
      />
      <path fill="#FFCFC3" d="M45.448 18.386H34.902a2.638 2.638 0 01-2.637-2.637V5.203" />
      <path
        fill="#FF8B66"
        fillRule="evenodd"
        d="M32.167 5.203c0-.305.247-.552.552-.552 1.544 0 3.026.615 4.118 1.707l7.456 7.456A5.827 5.827 0 0146 17.932a.552.552 0 01-1.104 0c0-1.25-.498-2.452-1.383-3.338l-7.456-7.456a4.723 4.723 0 00-3.338-1.383.552.552 0 01-.552-.552zM7.984 10.476a5.824 5.824 0 015.825-5.825.552.552 0 010 1.104 4.72 4.72 0 00-4.72 4.721.552.552 0 11-1.105 0zm13.764 20.328a5.242 5.242 0 015.244-5.244c2.912 0 5.245 2.486 5.245 5.244v3.401a.736.736 0 01-1.472 0v-3.401c0-1.972-1.7-3.773-3.773-3.773a3.77 3.77 0 00-3.772 3.773v3.401a.736.736 0 01-1.472 0v-3.401z"
        clipRule="evenodd"
      />
      <path
        fill="#FF8B66"
        fillRule="evenodd"
        d="M21.672 34.941c-1.023 0-1.854.83-1.854 1.853v5.368c0 1.023.831 1.854 1.854 1.854h10.64a1.855 1.855 0 001.854-1.854v-5.368c0-1.022-.83-1.853-1.853-1.853H21.672zm-3.325 1.853a3.326 3.326 0 013.325-3.325h10.64a3.326 3.326 0 013.326 3.325v5.368a3.326 3.326 0 01-3.325 3.325H21.672a3.326 3.326 0 01-3.325-3.325v-5.368z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default IconFileOptions
