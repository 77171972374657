import { useRef, useEffect } from 'react'
import { useThrottleFn } from '@umijs/hooks'

// Idea from https://github.com/n8tb1t/use-scroll-position/blob/master/src/useScrollPosition.jsx
// And https://medium.com/mtholla/create-a-transitioning-header-using-react-hooks-like-mediums-c70ed211f7c9
function useScrollThrottled(callback, wait) {
  const previousScrollTop = useRef(0)

  function handleScroll() {
    const currentScrollTop = document.scrollingElement.scrollTop
    callback({
      previousScrollTop: previousScrollTop.current,
      currentScrollTop,
    })
    previousScrollTop.current = currentScrollTop
  }

  const { run } = useThrottleFn(() => {
    handleScroll()
  }, wait)

  useEffect(() => {
    window.addEventListener('scroll', run, {
      capture: false,
      passive: true,
    })

    return () => window.removeEventListener('scroll', run)
  })
}

export default useScrollThrottled
