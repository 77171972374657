import React from 'react'

function IconClock(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M20 20.83l-2.26-2.26m-11.48 0L4 20.83M8.94 5.61a8 8 0 116.122 14.781 8 8 0 01-6.123-14.78M3.45 5.81l3-2.52m14.11 2.53l-3-2.52"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.75 9.432v4.135L15 15.549"
      />
    </svg>
  )
}

export default IconClock
