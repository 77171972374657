import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import useScrollThrottled from 'hooks/useScrollThrottled'
import Button from 'components/Common/Button'
import Burger from 'components/Common/Burger'

const Header = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const [shouldHideHeader, setShouldHideHeader] = useState(false)
  const [shouldShowShadow, setShouldShowShadow] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const hiddenStyle = shouldHideHeader ? ' shadow-none transform -translate-y-32' : ''
  const shadowStyle = shouldShowShadow ? ' shadow' : ' lg:py-8'

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  useEffect(() => {
    isMenuOpen ? disableBodyScroll(document.body) : enableBodyScroll(document.body)
  }, [isMenuOpen])

  useScrollThrottled(({ previousScrollTop, currentScrollTop }) => {
    const isScrolledDown = currentScrollTop > previousScrollTop
    const isMinimumScrolled = currentScrollTop > 80

    if (isScrolledDown !== shouldHideHeader && !isMenuOpen)
      setShouldHideHeader(isScrolledDown && isMinimumScrolled)
    setShouldShowShadow(currentScrollTop > 0)
  }, 200)

  return (
    <header
      className={
        'fixed w-full bg-white z-50 transition-all duration-500 px-6 lg:px-0 py-5' +
        hiddenStyle +
        shadowStyle
      }
    >
      <nav
        className={
          'fixed inset-0 bg-white transform transition-all duration-500 ' +
          (!isMenuOpen && '-translate-y-full')
        }
      >
        <ul
          className="text-center text-governor-600 h3 list-none overflow-auto pt-26"
          style={{ height: 'calc(100% - 10rem)' }}
        >
          <li className="mb-9">
            <Link to="/">{t('web.home')}</Link>
          </li>
          <li className="mb-9">
            <Link to="pricing">{t('web.pricing')}</Link>
          </li>
          <li className="600 mb-9">
            <Link to="/">{t('web.faq')}</Link>
          </li>
        </ul>
        <div className="absolute text-center inset-x-0 bottom-0">
          <Link to="/" className="w-full block h4 text-governor-600 p-6">
            {t('web.login')}
          </Link>
          <Link
            to="/"
            className="w-full bg-governor-600 inline-block h4 text-white rounded-t-6 p-6"
          >
            {t('web.signup')}
          </Link>
        </div>
      </nav>
      <nav className="relative container flex justify-between items-center mx-auto">
        <a className="flex lg:flex-1 items-center" href="/">
          <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.2478 1.33334V17.3333C28.2475 19.4315 27.6922 21.4929 26.6373 23.312C25.5824 25.131 24.0647 26.6441 22.2358 27.7003C20.4068 28.7565 18.3304 29.3189 16.2137 29.3314C14.097 29.3439 12.0139 28.806 10.1724 27.7715C8.33086 26.737 6.79516 25.2419 5.71848 23.4354C4.6418 21.629 4.06178 19.5742 4.03628 17.4762C4.01078 15.3782 4.54068 13.3102 5.57313 11.4785C6.60558 9.64689 8.1045 8.11557 9.92036 7.03734L18.8319 1.93734V5.63068L28.2478 1.33334ZM16.1416 10.6667C14.3578 10.6667 12.6471 11.3691 11.3858 12.6193C10.1245 13.8695 9.41593 15.5652 9.41593 17.3333C9.41593 19.1015 10.1245 20.7971 11.3858 22.0474C12.6471 23.2976 14.3578 24 16.1416 24C17.9254 24 19.636 23.2976 20.8974 22.0474C22.1587 20.7971 22.8673 19.1015 22.8673 17.3333C22.8673 15.5652 22.1587 13.8695 20.8974 12.6193C19.636 11.3691 17.9254 10.6667 16.1416 10.6667Z"
              fill="#41E0EB"
            />
          </svg>
          <span className="text-3xl text-governor-600 leading-none font-extrabold ml-1">
            UPY.IO
          </span>
        </a>
        <ul className="hidden lg:flex flex-1 justify-center items-center list-none text-governor-600">
          <li className="mr-12 xl:mr-18">
            <Link to="/">{t('web.home')}</Link>
          </li>
          <li className="mr-12 xl:mr-18">
            <Link to="pricing">{t('web.pricing')}</Link>
          </li>
          <li>
            <a href="/">{t('web.faq')}</a>
          </li>
        </ul>
        <div className="hidden lg:flex flex-1 justify-end items-center">
          <Link to="/" className="text-governor-600">
            {t('web.login')}
          </Link>
          <Button as={Link} to="pricing" className="ml-6 xl:ml-8">
            {t('web.signup')}
          </Button>
        </div>
        <Burger
          className="lg:hidden text-governor-600 cursor-pointer"
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
        />
      </nav>
    </header>
  )
}

export default Header
