import React from 'react'

function IconPlanFree(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 72 72">
      <path
        stroke="#AEBACF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M35.998 42.069l5.265 2.766c1.026.54 2.223-.333 2.028-1.473L42.285 37.5l4.26-4.149c.83-.807.371-2.217-.774-2.382l-5.886-.855-2.632-5.334c-.512-1.038-1.994-1.038-2.505 0l-2.63 5.337-5.886.855c-1.146.168-1.605 1.575-.774 2.382l4.26 4.149-1.009 5.862c-.195 1.14 1.002 2.01 2.028 1.473l5.266-2.766"
      />
      <path
        fill="#DDE4F1"
        d="M27.144 57.687c-.237 3.363-2.547 5.655-5.913 5.865.237-3.366 2.547-5.655 5.913-5.865zm0-4.5c-.093 0-.186.003-.279.009-5.655.351-9.723 4.386-10.125 10.038a4.512 4.512 0 001.293 3.486 4.495 4.495 0 003.474 1.323c5.655-.351 9.723-4.386 10.125-10.038a4.512 4.512 0 00-1.293-3.486 4.495 4.495 0 00-3.195-1.332zm0 9h.015-.015zM11.136 45.885c1.44 0 2.883.549 4.15 1.65-1.273 1.122-2.728 1.683-4.183 1.683-1.44 0-2.883-.55-4.149-1.65 1.272-1.122 2.727-1.683 4.182-1.683zm0-4.5c-2.6 0-5.079.972-7.16 2.81a4.497 4.497 0 00-1.522 3.394 4.506 4.506 0 001.551 3.38c2.073 1.8 4.527 2.752 7.098 2.752 2.601 0 5.076-.972 7.158-2.811a4.497 4.497 0 001.521-3.393 4.506 4.506 0 00-1.55-3.381c-2.07-1.8-4.525-2.751-7.096-2.751zm33.72 16.302c3.366.21 5.676 2.502 5.913 5.865-3.366-.21-5.676-2.502-5.913-5.865zm0-4.5a4.5 4.5 0 00-4.488 4.818c.399 5.652 4.467 9.687 10.125 10.038a4.5 4.5 0 004.767-4.809c-.399-5.652-4.467-9.687-10.125-10.038a4.332 4.332 0 00-.279-.009zm16.008-7.302c1.455 0 2.91.56 4.182 1.683-1.266 1.098-2.71 1.65-4.15 1.65-1.454 0-2.91-.561-4.181-1.683 1.266-1.101 2.709-1.65 4.149-1.65zm0-4.5c-2.571 0-5.025.95-7.098 2.75a4.502 4.502 0 00-.03 6.774c2.082 1.84 4.557 2.812 7.158 2.812 2.57 0 5.025-.951 7.098-2.751a4.502 4.502 0 00.03-6.774c-2.082-1.84-4.557-2.811-7.158-2.811z"
      />
    </svg>
  )
}

export default IconPlanFree
