import React from 'react'

function IconPassword(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8.211 9.219V7.003c0-2.052 1.537-3.891 3.586-3.998a3.79 3.79 0 013.993 3.784v2.43"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.596 9.904a6.5 6.5 0 11-9.192 9.192 6.5 6.5 0 019.192-9.192M12 16.5v-2.55"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.997 12.431a.75.75 0 10.007 1.5.75.75 0 00-.007-1.5z"
      />
    </svg>
  )
}

export default IconPassword
