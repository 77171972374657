import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Loadable from 'react-loadable'
import Header from 'layouts/web/Header'
import Footer from 'layouts/web/Footer'

const Home = Loadable({
  loader: () => import('views/web/Home'),
  loading() {
    return <div>Loading...</div>
  },
  delay: 300,
})

const Pricing = Loadable({
  loader: () => import('views/web/Pricing'),
  loading() {
    return <div>Loading...</div>
  },
  delay: 300,
})

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="pricing" element={<Pricing />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
