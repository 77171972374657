import React from 'react'

function IconEyeClose(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 19c-.842 0-1.685-.178-2.504-.495m11.386-6.037C18.99 15.967 15.495 19 12 19m7.079-10.079a15.135 15.135 0 011.803 2.612.987.987 0 010 .935M5 19L19 5m-9.227 9.227a3.15 3.15 0 014.455-4.455"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M17.044 6.956C15.497 5.759 13.748 5 12 5c-3.495 0-6.99 3.033-8.882 6.533a.987.987 0 000 .935c.946 1.749 2.292 3.381 3.838 4.577"
      />
    </svg>
  )
}

export default IconEyeClose
