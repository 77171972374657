import React from 'react'
import { useTranslation } from 'react-i18next'
import Select, { Option } from 'rc-select'
import { IconArrow, IconFacebook, IconTwitter } from 'components/InlineSVG'

const Footer = () => {
  const { t, i18n } = useTranslation()

  return (
    <footer className="container mx-auto px-9 pb-7">
      <nav className="flex flex-col lg:flex-row items-center border-t border-gray-100 py-12">
        <div className="order-1 lg:order-0 flex flex-1 justify-center lg:justify-start items-center pt-6 pb-9 lg:py-0">
          <a className="mr-8" href="/#">
            <IconFacebook width="24" height="24" />
          </a>
          <a href="/#">
            <IconTwitter width="24" height="24" />
          </a>
        </div>
        <ul className="order-0 lg:order-1 flex flex-1 justify-center items-center list-none text-governor-600">
          <li className="mr-12 xl:mr-18">
            <a href="/">{t('web.home')}</a>
          </li>
          <li className="mr-12 xl:mr-18">
            <a href="/">{t('web.pricing')}</a>
          </li>
          <li className="mr-12 xl:mr-18">
            <a href="/">{t('web.faq')}</a>
          </li>
          <li>
            <a href="/">{t('web.terms')}</a>
          </li>
        </ul>
        <div className="order-2 flex flex-1 items-center justify-center lg:justify-end">
          <Select
            className="w-36"
            prefixCls="select"
            transitionName="slide-up"
            defaultValue={i18n.language}
            onChange={(e) => i18n.changeLanguage(e)}
            inputIcon={
              <IconArrow
                className="inline align-center ml-4"
                hoverDirection="up"
                width="24"
                height="24"
              />
            }
            menuItemSelectedIcon={''}
          >
            <Option value={'en'}>English</Option>
            <Option value={'es'}>Español</Option>
            <Option value={'zh-TW'}>繁體中文</Option>
          </Select>
        </div>
      </nav>
      <div className="text-center text-gray-500">© 2020 Upy.io All rights reserved.</div>
    </footer>
  )
}
export default Footer
