import React from 'react'

function IconTwitter(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path
        fill="#3F36A4"
        fillRule="evenodd"
        d="M4.848 4.102a.75.75 0 01.613.393C6.35 6.149 7.89 7.657 9.557 8.35c.202-1.068.678-2.016 1.395-2.75.934-.953 2.235-1.5 3.748-1.5 1 0 1.843.191 2.555.571a4.43 4.43 0 011.431 1.23H21a.75.75 0 01.624 1.165L19.949 9.58c-.043 3.194-.982 5.999-2.774 8.03C15.33 19.7 12.64 20.9 9.3 20.9c-1.945 0-3.359-.512-4.462-1.292-1.08-.765-1.819-1.76-2.444-2.616a.75.75 0 01.397-1.162l.014-.005.085-.027a142.216 142.216 0 001.233-.411l.271-.09C1.876 12.043 1.865 7.66 4.19 4.413a.75.75 0 01.658-.312zm1.09 12.26l-.418.14-.921.308-.37.123c.435.546.9 1.043 1.476 1.45.832.59 1.94 1.017 3.595 1.017 2.96 0 5.22-1.05 6.75-2.784 1.54-1.745 2.4-4.254 2.4-7.266a.75.75 0 01.126-.416L19.599 7.4H18.3a.75.75 0 01-.626-.337c-.312-.474-.674-.827-1.125-1.068-.453-.242-1.043-.395-1.849-.395-1.138 0-2.044.404-2.676 1.05-.636.65-1.037 1.589-1.074 2.725a.75.75 0 01-.927.704C7.95 9.575 6.06 8.046 4.806 6.278c-1.449 2.84-1.022 6.368 1.427 8.845a.75.75 0 01-.295 1.238z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default IconTwitter
