import React from 'react'

function IconFacebook(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path
        stroke="#3F36A4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M9.684 11.314h4.63m.002-3.859h-.81a2.278 2.278 0 00-2.277 2.277v6.813"
      />
      <path
        stroke="#3F36A4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.5}
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      />
    </svg>
  )
}

export default IconFacebook
