import React from 'react'

function IconExtZip(props) {
  return (
    <svg {...props} viewBox="0 0 500 520">
      <defs>
        <filter
          id="prefix__a"
          width="104.2%"
          height="108.3%"
          x="-2.1%"
          y="-2.1%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feColorMatrix
            in="shadowOffsetOuter1"
            values="0 0 0 0 0.858859196 0 0 0 0 0.871765907 0 0 0 0 0.884672619 0 0 0 1 0"
          />
        </filter>
        <path
          id="prefix__b"
          d="M9.5 8h21A1.5 1.5 0 0132 9.5v21a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 018 30.5v-21A1.5 1.5 0 019.5 8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-166.67 -166.89) scale(20.83333)">
        <use fill="#000" filter="url(#prefix__a)" href="#prefix__b" />
        <use fill="#F7F9FA" href="#prefix__b" />
        <path
          fill="#637282"
          d="M18 8h2v1h-2V8zm2 1h2v1h-2V9zm-2 1h2v1h-2v-1zm2 1h2v1h-2v-1zm-2 1h2v1h-2v-1zm2 1h2v1h-2v-1zm-2 1h2v1h-2v-1zm2 1h2v1h-2v-1zm-2 1h2v1h-2v-1zm2 1h2v1h-2v-1zm-2 1h2v1h-2v-1zm0 1h4v4.5a.5.5 0 01-.49.5h-3.02a.49.49 0 01-.49-.5V19zm1 3v1h2v-1h-2z"
        />
      </g>
    </svg>
  )
}

export default IconExtZip
