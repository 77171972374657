import React from 'react'

function IconStats(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 56 56">
      <path fill="#fff" d="M0 0h56v56H0V0z" />
      <path
        fill="#FFF1F3"
        d="M39.639 46.06H16.36a6.422 6.422 0 01-6.42-6.42V16.36a6.422 6.422 0 016.421-6.42H39.64a6.422 6.422 0 016.42 6.42v23.28a6.422 6.422 0 01-6.42 6.421z"
      />
      <path
        stroke="#FF637F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M16.361 46.06a6.422 6.422 0 01-6.421-6.42m29.699-29.7a6.422 6.422 0 016.42 6.421M9.94 31.465c1.635 0 3.205-.65 4.36-1.805l3.317-3.317a6.169 6.169 0 018.721 0l3.317 3.317a6.168 6.168 0 008.721 0l3.317-3.317a6.169 6.169 0 014.36-1.806"
      />
    </svg>
  )
}

export default IconStats
