import React from 'react'

function IconHighAvailability(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 56 56">
      <path
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M32.455 23.545a6.3 6.3 0 11-8.91 8.91 6.3 6.3 0 018.91-8.91"
      />
      <path
        fill="#F0FAF7"
        d="M12.25 28c0 .693.063 1.386.147 2.058l-3.705 2.898a2.095 2.095 0 00-.523 2.697l3.295 5.7a2.095 2.095 0 002.594.897l3.318-1.333c.656-.263 1.386-.158 1.974.231.514.341 1.046.654 1.596.934.63.32 1.088.891 1.188 1.591l.506 3.53A2.093 2.093 0 0024.712 49h6.573a2.092 2.092 0 002.072-1.797l.507-3.528c.1-.7.562-1.276 1.194-1.593a15.405 15.405 0 001.59-.924c.592-.392 1.325-.504 1.983-.238l3.31 1.33a2.097 2.097 0 002.595-.896l3.295-5.7a2.094 2.094 0 00-.523-2.698l-3.705-2.898c.084-.672.147-1.365.147-2.058 0-.693-.063-1.386-.147-2.058l3.705-2.898a2.095 2.095 0 00.523-2.697l-3.295-5.7a2.095 2.095 0 00-2.594-.897l-3.311 1.33c-.658.264-1.39.154-1.984-.238a15.405 15.405 0 00-1.589-.924c-.632-.317-1.094-.893-1.194-1.593l-.504-3.528A2.093 2.093 0 0031.288 7h-6.573c-1.04 0-1.925.765-2.072 1.797l-.509 3.532c-.1.698-.56 1.272-1.188 1.592-.55.28-1.082.595-1.596.933-.59.387-1.32.492-1.976.229l-3.316-1.333a2.095 2.095 0 00-2.594.896l-3.295 5.7a2.094 2.094 0 00.523 2.698l3.705 2.898A16.654 16.654 0 0012.25 28z"
      />
      <path
        stroke="#5CD1A7"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M24.715 7c-1.04 0-1.925.765-2.072 1.797l-.509 3.532c-.1.698-.56 1.272-1.188 1.592-.55.28-1.082.595-1.596.933-.59.387-1.32.492-1.976.229l-3.316-1.333a2.095 2.095 0 00-2.594.896l-3.295 5.7a2.094 2.094 0 00.523 2.698M19.35 41.148c.514.341 1.046.654 1.597.934.63.32 1.087.891 1.187 1.591l.507 3.53A2.093 2.093 0 0024.713 49h6.573c1.04 0 1.925-.765 2.072-1.797l.506-3.528c.1-.7.562-1.276 1.195-1.593a15.397 15.397 0 001.589-.924c.592-.392 1.325-.504 1.983-.238l3.31 1.33a2.097 2.097 0 002.596-.896l3.294-5.7a2.095 2.095 0 00-.522-2.698l-3.706-2.898c.084-.672.147-1.365.147-2.058 0-.693-.063-1.386-.147-2.058"
      />
      <circle cx={28} cy={28} r={9.116} fill="#BDEDDC" />
    </svg>
  )
}

export default IconHighAvailability
