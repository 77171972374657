import React from 'react'

function IconDice(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18 20H6c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2z"
      />
      <circle cx={8} cy={8} r={1} fill="currentColor" />
      <circle cx={8} cy={16} r={1} fill="currentColor" />
      <circle cx={12} cy={12} r={1} fill="currentColor" />
      <circle cx={16} cy={8} r={1} fill="currentColor" />
      <circle cx={16} cy={16} r={1} fill="currentColor" />
    </svg>
  )
}

export default IconDice
