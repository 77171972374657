import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SafeAnchor from './SafeAnchor'

const propTypes = {
  href: PropTypes.string,
  theme: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'reset', 'submit', null]),
  as: PropTypes.elementType,
}

const defaultProps = {
  theme: 'default',
  color: 'governor',
  size: 'lg',
  as: 'button',
}

const Button = ({ className, theme, color, size, as, ...props }) => {
  const variants = {
    theme: {
      default: `bg-${color}-600 text-white hover:bg-${color}-500 active:bg-${color}-800`,
      outline: `bg-${color}-100 text-${color}-600 border border-transparent hover:shadow-inner-${color} active:bg-${color}-300`,
      transparent: `text-${color}-600 border border-gray-300`,
      disabled: `bg-gray-100 text-gray-500 cursor-not-allowed`,
    },
    size: {
      md: `px-9 py-3`,
      lg: `px-9 py-4`,
    },
  }

  const classes = classNames(
    'btn',
    className,
    !props.disabled && variants.theme[theme],
    props.disabled && variants.theme.disabled,
    size && variants.size[size],
  )

  if (props.href) {
    return <SafeAnchor {...props} className={classes} />
  }

  const Component = as
  return <Component {...props} className={classes} />
}

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
