import React from 'react'

function BubbleBenefits(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 166 164">
      <path
        fill="#EDFDFD"
        fillRule="evenodd"
        d="M3.424 106.444c-4.156-17.229-5.15-35.119.54-51.896 5.843-17.226 16.424-32.808 31.55-42.713C50.634 1.935 68.98-.811 86.969.195c17.945 1.003 35.719 5.928 49.728 17.298 14.043 11.398 23.511 27.677 27.538 45.414 3.928 17.298.957 35.136-5.499 51.644-6.404 16.375-15.875 31.953-30.769 41.098-14.647 8.993-32.366 8.583-49.5 8.187-17.671-.409-36.581.275-50.672-10.502-14.283-10.923-20.129-29.308-24.37-46.89z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default BubbleBenefits
