import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right', null]),
  hoverDirection: PropTypes.oneOf(['up', 'down', 'left', 'right', null]),
}

const defaultProps = {
  direction: 'down',
}

function IconArrow({ className, direction, hoverDirection, ...props }) {
  const variants = {
    direction: {
      up: `rotate-180`,
      down: ``,
      left: `rotate-90`,
      right: `-rotate-90`,
    },
    hoverDirection: {
      up: `group-hover:rotate-180`,
      down: `group-hover:rotate-0`,
      left: `group-hover:rotate-90`,
      right: `group-hover:-rotate-90`,
    },
  }

  const classes = classNames(
    'transform transition-all duration-300',
    className,
    direction && variants.direction[direction],
    hoverDirection && variants.hoverDirection[hoverDirection],
  )

  return (
    <svg {...props} className={classes} fill="none" viewBox="0 0 24 24">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M8 10l4 4 4-4"
      />
    </svg>
  )
}

IconArrow.propTypes = propTypes
IconArrow.defaultProps = defaultProps

export default IconArrow
